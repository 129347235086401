import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "../App.css";
import "./mainpage.css";
import ModeToggle from "../components/header/ModeToggle";
import { isMobile } from "react-device-detect";
import HeaderMobile from "../components/header-mobile/HeaderMobile";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../store/reducers/layoutSlice";
import VideoText from "../components/mainpage/VideoText";
import ScrollEffect from "../components/mainpage/ScrollEffect";
import ScrollEffectMobile from "../components/mainpage/ScrollEffectMobile";
import { useTheme } from "@emotion/react";
import SmokeBg from "../components/mainpage/SmokeBg";
import AboutUs from "../components/mainpage/AboutUs";


function Mainpage() {
	const theme = useTheme();
	const screenSize = useSelector(selectScreenSize);

	const isSmallScreen = screenSize === "small" || isMobile;

	return (
		<div
			className="App"
			style={{ backgroundColor: theme.palette.primary.main }}
		>
			{isSmallScreen ? (
				<HeaderMobile />
			) : (
				<>
					<Header />
					<div className="mode-desktop">
						<ModeToggle />
					</div>
				</>
			)}

			<div className="video-container">
				<SmokeBg />
				<VideoText />
			</div>
			 <ScrollEffectMobile/>
			<AboutUs />
			<Footer />
		</div>
	);
}

export default Mainpage;

import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@emotion/react";

const CardChart = ({ segments, onSegmentClick, activeSegmentIndex }) => {
  const theme = useTheme();
  const [isInView, setIsInView] = useState(false);
  const cardChartRef = useRef(null);

  useEffect(() => {
    // Intersection Observer to trigger animation when in view
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (cardChartRef.current) {
      observer.observe(cardChartRef.current);
    }

    return () => {
      if (cardChartRef.current) {
        observer.unobserve(cardChartRef.current);
      }
    };
  }, []);

  if (!segments || segments.length === 0) {
    return <div>No segments available</div>;
  }

  const getCardSize = (index) => {
    return index === activeSegmentIndex ? "9vh" : "6vh";
  };

  const getIconSize = (index) => {
    return index === activeSegmentIndex ? "2.5em" : "1.8em";
  };

  const handleClick = (index) => {
    onSegmentClick(index);
  };

  return (
    <div
      className={`card-chart-container ${isInView ? "in-view" : ""}`}
      ref={cardChartRef}
    >
      {/* Render the cards */}
      <div className="card-group">
        {segments.map((segment, index) => (
          <div
            key={index}
            className={`card ${index === activeSegmentIndex ? "active" : ""}`}
            style={{ width: getCardSize(index), height: getCardSize(index) }}
            onClick={() => handleClick(index)}
          >
            {React.cloneElement(segment.icon, {
              style: {
                fontSize: getIconSize(index),
                color: theme.palette.text.main,
                margin: "auto",
                display: "block",
                transition: "font-size 0.3s ease",
              },
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardChart;

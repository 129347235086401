import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { themeCreation } from "./theme/designTokens";
import { useSelector } from "react-redux";
import { selectActiveTheme } from "./store/reducers/userSlice";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScreenSizeListener from "./helpers/ScreenSizeListener";
import { Route, Routes } from "react-router-dom";
import { selectScreenSize } from "./store/reducers/layoutSlice";
import LoadingModal from "./components/other/LoadingModal";
import Mainpage from "./pages/Mainpage";

function App() {
	const themeMode = useSelector(selectActiveTheme);
	const [theme, setTheme] = useState(themeCreation());

	useEffect(() => {
		setTheme(themeCreation(themeMode));
	}, [themeMode]);

	const screenSize = useSelector(selectScreenSize);

	return (
		<ThemeProvider theme={theme}>
			<ScreenSizeListener />
			<Routes>
				<Route path="/" element={<Mainpage />} />
			</Routes>
			{/* <LoadingModal /> */}
		</ThemeProvider>
	);
}

export default App;

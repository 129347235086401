import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LightIcon } from "../../media/mode/light-mode.svg";
import { ReactComponent as DarkIcon } from "../../media/mode/dark-mode.svg";
import { setThemeMode } from "../../store/reducers/userSlice";

function ModeToggle() {
	const dispatch = useDispatch();
	const currentTheme = useSelector((state) => state.user.theme);

	const handleModeSelect = () => {
		// Toggle between light and dark modes
		const newMode = currentTheme === "light" ? "dark" : "light";
		dispatch(setThemeMode(newMode)); // Dispatch action to toggle the theme
	};

	return (
		<div className={`mode-container ${currentTheme}`} onClick={handleModeSelect}>
			<div className="mode-container-theme">
				{currentTheme === "light" ? <DarkIcon /> : <LightIcon />}
			</div>
		</div>
	);
}

export default ModeToggle;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PieChart from "./PieChart";
import PsychologyIcon from "@mui/icons-material/Psychology";
import BarChartIcon from "@mui/icons-material/BarChart";
import CampaignIcon from "@mui/icons-material/Campaign";
import LanguageIcon from "@mui/icons-material/Language";
import "./aboutus.css";
import PieText from "./PieText";
import data from "../../media/mainpage-text/text.json";
import { selectActiveLanguage } from "../../store/reducers/userSlice";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import CardChart from "./CardChart";

const segments = [
	{
		label: "Segment 1",
		value: 25,
		color: "#6ae7da",
		icon: <CampaignIcon />,
	},
	{
		label: "Segment 2",
		value: 25,
		color: "#6ae7da",
		icon: <LanguageIcon />,
	},
	// {
	//   label: "Segment 3",
	//   value: 25,
	//   color: "#6ae7da",
	//   icon: <BarChartIcon />,
	// },
	{
		label: "Segment 4",
		value: 25,
		color: "#6ae7da",
		icon: <PsychologyIcon />,
	},
];

function AboutUs() {
	const theme = useTheme();
	const language = useSelector(selectActiveLanguage);
	const screenSize = useSelector(selectScreenSize);
	const [activeSegmentIndex, setActiveSegmentIndex] = useState(0);
	const [activeSegment, setActiveSegment] = useState({ heading: "", text: "" });

	useEffect(() => {
		setActiveSegment(data[segments[activeSegmentIndex].label][language]);
	}, [language, activeSegmentIndex]);

	const handleIconClick = (direction) => {
		setActiveSegmentIndex((prevIndex) => {
			const nextIndex =
				direction === "next"
					? (prevIndex + 1) % segments.length
					: (prevIndex - 1 + segments.length) % segments.length;

			setActiveSegment(data[segments[nextIndex].label][language]);
			return nextIndex;
		});
	};

	const handleSegmentClick = (segmentIndex) => {
		setActiveSegmentIndex(segmentIndex);
		setActiveSegment(data[segments[segmentIndex].label][language]);
	};

	return (
		<div
			className="about-us-container"
			style={{
				flexDirection: screenSize === "small" || isMobile ? "column" : "row",
			}}
		>
			<div
				className="about-us-piechart-container"
				style={{ width: screenSize === "small" || isMobile ? "100%" : "33%" }}
			>
				{screenSize === "small" ? (
					<CardChart
						segments={segments}
						activeSegmentIndex={activeSegmentIndex}
						onSegmentClick={handleSegmentClick}
					/>
				) : (
					<div style={{height: "120%", display:"flex", justifyContent:"center"}}>
					<PieChart
						segments={segments}
						activeSegmentIndex={activeSegmentIndex}
						onSegmentClick={handleSegmentClick}
					/>
					</div>
				)}
			</div>
			<div style={{backgroundColor: screenSize === "small" || isMobile ? "" : theme.palette.primary.glass,
					width: screenSize === "small" || isMobile ? "100%" : "67%",
					height: "120%",
					display:"flex",
					justifyContent:"center"}}>
			<div
				className="about-us-text-container"
				style={{
					height: screenSize === "small" || isMobile ? "60vh" : "",
				}}
			>
				
				<PieText
					heading={activeSegment.heading}
					text={activeSegment.text}
					onIconClick={handleIconClick}
				/>
			</div>
			</div>
		</div>
	);
}

export default AboutUs;

import React from "react";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import {  selectActiveLanguage } from "../../store/reducers/userSlice";
import { translations } from "../../helpers/translations";
import LanguageOption from "./LanguageOption";
import { selectScreenSize } from "../../store/reducers/layoutSlice";

function LanguageModal({ onLanguageSelect }) {
    const theme = useTheme();
    const screenSize = useSelector(selectScreenSize);
    const currentLanguage = useSelector(selectActiveLanguage);

    const modalClassName =
        isMobile || screenSize === "small"
            ? "language-mobile-modal"
            : "language-desktop-modal";

    // Get the translations for the current language
    const translatedLanguages = translations[currentLanguage] || {};

    // Array of language options with translated names
    const languageOptions = [
        { code: "en", label: translatedLanguages.english || "English" },
        { code: "tr", label: translatedLanguages.turkish || "Turkish" },
        { code: "sr", label: translatedLanguages.serbian || "Serbian" },
    ];

    return (
        <div
            className={modalClassName}
            style={{
                backgroundColor:
                    screenSize === "small" || isMobile
                        ? undefined
                        : theme.palette.primary.main,
            }}
        >
            {languageOptions.map(({ code, label }) => (
                <LanguageOption
                    key={code}
                    language={{ code, label }}
                    onClick={() => onLanguageSelect(code)}
                />
            ))}
        </div>
    );
}

export default LanguageModal;

import React, { useState } from "react";
import "./headermobile.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { translations } from "../../helpers/translations";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LanguageModal from "../header/LanguageModal";
import {
	setLanguage,
	LANGUAGE_OPTIONS,
	setThemeMode,
} from "../../store/reducers/userSlice";
import { ReactComponent as EnglishIcon } from "../../media/language/english.svg";
import { ReactComponent as TurkishIcon } from "../../media/language/turkey.svg";
import { ReactComponent as SerbianIcon } from "../../media/language/serbian.svg";
import ModeModal from "./ModeModal";

const languageIcons = {
	[LANGUAGE_OPTIONS.ENGLISH]: EnglishIcon,
	[LANGUAGE_OPTIONS.TURKISH]: TurkishIcon,
	[LANGUAGE_OPTIONS.SERBIAN]: SerbianIcon,
};

function HeaderMobileModal() {
	const dispatch = useDispatch();
	const currentLanguage = useSelector((state) => state.user.language);
	const theme = useTheme();
	const currentTheme = useSelector((state) => state.user.theme);
	const navigate = useNavigate();

	const { aboutUs, services, language, mode } =
		translations[currentLanguage] || translations.en;

	const [isLanguageExpanded, setIsLanguageExpanded] = useState(false);
	const [isModeExpanded, setIsModeExpanded] = useState(false);

	const toggleLanguageExpand = () => setIsLanguageExpanded(!isLanguageExpanded);
	const toggleModeExpand = () => setIsModeExpanded(!isModeExpanded);

	const handleLanguageChange = (language) => {
		dispatch(setLanguage({ language }));
	};

	const handleModeChange = () => {
		dispatch(setThemeMode());
	};

	const CurrentLanguageIcon = languageIcons[currentLanguage] || EnglishIcon;

	const margin = currentTheme === "dark" ? "65px" : "65px";

	return (
		<div
			className="menu-mobile-modal-container"
			style={{
				backgroundColor: theme.palette.primary.lighter,
				color: theme.palette.text.main,
				marginTop: margin,
			}}
		>
			<div className="menu-mobile-modal-options-container">
				<div onClick={() => navigate("/about-us")}>
					<div className="menu-mobile-modal-option menu-mobile-modal-link">
						{aboutUs}
					</div>
				</div>
				<div onClick={() => navigate("/services")}>
					<div className="menu-mobile-modal-option menu-mobile-modal-link">
						{services}
					</div>
				</div>
				<div
					style={{
						backgroundColor: isLanguageExpanded
							? theme.palette.hover.main
							: "transparent",
					}}
				>
					<div
						className="menu-mobile-modal-option menu-mobile-modal-link"
						onClick={toggleLanguageExpand}
					>
						{language}
						<ArrowDropDownIcon
							className={`arrow-icon ${isLanguageExpanded ? "expanded" : ""}`}
							style={{ fontSize: "3rem" }}
						/>
					</div>
				</div>
				{isLanguageExpanded && (
					<div className="expanded-content">
						<LanguageModal
							onLanguageSelect={handleLanguageChange}
							CurrentLanguageIcon={CurrentLanguageIcon}
						/>
					</div>
				)}
				<div
					style={{
						backgroundColor: isModeExpanded
							? theme.palette.hover.main
							: "transparent",
					}}
				>
					<div
						className="menu-mobile-modal-option menu-mobile-modal-link"
						onClick={toggleModeExpand}
					>
						{mode}
						<ArrowDropDownIcon
							className={`arrow-icon ${isModeExpanded ? "expanded" : ""}`}
							style={{ fontSize: "3rem" }}
						/>
					</div>
				</div>
				{isModeExpanded && (
					<div className="expanded-content">
						<ModeModal
							onModeSelect={handleModeChange}
							currentTheme={currentTheme}
						/>
					</div>
				)}
			</div>
		</div>
	);
}

export default HeaderMobileModal;

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import Logo from "./Logo";
import LanguageSelect from "./LanguageSelect";
import { translations } from "../../helpers/translations"; 

function Header() {
    const theme = useTheme();
    const navigate = useNavigate();
    const currentLanguage = useSelector((state) => state.user.language); 

    const { aboutUs, services, language } = translations[currentLanguage] || translations.en;

    return (
        <div
            className="header-container"
            style={{ backgroundColor: theme.palette.primary.main, color:theme.palette.text.main }}
        >
            <Logo />

            <div className="menu-desktop">
                <div
                    className="menu-desktop-links menu-desktop-font"
                    onClick={() => navigate("/about-us")}
                >
                    {aboutUs}
                </div>
                <div
                    className="menu-desktop-links menu-desktop-font"
                    onClick={() => navigate("/services")}
                >
                    {services}
                </div>
                <div className="menu-desktop-font menu-desktop-language">
                    {language}
                    &nbsp;
                    <LanguageSelect />
                </div>
            </div>
        </div>
    );
}

export default Header;

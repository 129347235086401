import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import webGLFluidEnhanced from 'webgl-fluid-enhanced';
import { selectActiveTheme } from "../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import fluidVideo from "../../media/video-mobile/fluid.mp4";
import fluidDarkVideo from "../../media/video-mobile/fluid-dark.mp4";
import "./smokebg.css";

const SmokeBg = () => {
    const canvasRef = useRef(null);
    const [videoSrc, setVideoSrc] = useState(fluidVideo);
    const themeMode = useSelector(selectActiveTheme);

    useEffect(() => {
        setVideoSrc(themeMode === "dark" ? fluidDarkVideo : fluidVideo);
    }, [themeMode]);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (canvas) {
            // Initialize webGLFluidEnhanced with custom settings and palette
            webGLFluidEnhanced.simulation(canvas, {
                BRIGHTNESS: 0.5,
                SIM_RESOLUTION: 64,  // Reduced for performance
                DYE_RESOLUTION: 512,  // Reduced for performance
                CAPTURE_RESOLUTION: 256,  // Reduced for performance
                DENSITY_DISSIPATION: 0.4,  // Slows down the disappearance of colors
                VELOCITY_DISSIPATION: 0.02,  // Smoother fluid interaction and sustained movement
                PRESSURE: 0.2,
                PRESSURE_ITERATIONS: 10,  // Reduce pressure iterations for performance
                CURL: 30,
                INITIAL: true,
                SPLAT_AMOUNT: 2,
                SPLAT_RADIUS: 0.18,
                SPLAT_FORCE: 4000,  // Increased force for more immediate interaction
                SPLAT_KEY: 'Space',
                SHADING: true,
                COLORFUL: true,
                COLOR_PALETTE: [
                    '#6ae7da',  // Prevalent teal color
                    '#55d1c6',  // Slightly darker teal
                    '#42bdb2',  // Muted teal
                    '#2c5e54',  // Dark green-teal
                    '#1b4332',  // Rich dark green
                    '#0b2d20',  // Very deep dark green
                    '#0f3b3b',  // Dark teal-green
                    '#0d2626',  // Almost black-green
                    '#15252d',  // Very dark blue-green for more contrast
                    '#0c1a1a',  // Almost black
                    '#ffffff'   // Pure white for contrast
                ],
                HOVER: true,
                BACK_COLOR: themeMode === "dark" ? '#2A6358' : '#FFFFFF',
                TRANSPARENT: false,
                BLOOM: true,
                BLOOM_ITERATIONS: 6,  // Reduced for performance
                BLOOM_RESOLUTION: 256,
                BLOOM_INTENSITY: 0.6,
                BLOOM_THRESHOLD: 0.6,
                BLOOM_SOFT_KNEE: 0.7,
                SUNRAYS: true,
                SUNRAYS_RESOLUTION: 196,
                SUNRAYS_WEIGHT: 1.0,
            });
        }
    }, [themeMode]);

    return (
        <div
            className="smoke-container"
            style={{
                height: isMobile ? "80vh" : "95vh",
            }}
        >
            <canvas ref={canvasRef} style={{ width: "100%", minHeight: "100vh" }}></canvas>
        </div>
    );
};

export default SmokeBg;

import React, { useState, useRef, useEffect } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SquareIcon from "@mui/icons-material/Square";
import { useTheme } from "@emotion/react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { Height } from "@mui/icons-material";

const PieText = ({ heading, text, onIconClick }) => {
	const paragraphs = text.split("\n\n");
	const theme = useTheme();
	const screenSize = useSelector(selectScreenSize);
	const [isVisible, setIsVisible] = useState(false);
	const pieTextRef = useRef(null); // Ref to observe the component

	// State to track hover status for the arrow icons
	const [isPrevHovered, setIsPrevHovered] = useState(false);
	const [isNextHovered, setIsNextHovered] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsVisible(true); // Trigger animation when in view
				}
			},
			{ threshold: 0.5 }, // Trigger when 50% of the component is visible
		);

		if (pieTextRef.current) {
			observer.observe(pieTextRef.current);
		}

		return () => {
			if (pieTextRef.current) {
				observer.unobserve(pieTextRef.current);
			}
		};
	}, []);

	return (
		<div
			ref={pieTextRef}
			className={`pie-text-container ${isVisible ? "visible" : ""}`}
			style={{
				justifyContent:
					screenSize === "small" || isMobile ? "flex-start" : "space-evenly",
				justifyContent:
					screenSize === "small" || isMobile ? "flex-start" : "space-evenly",
			}}
		>
			<h1
				className="pie-text-heading"
				style={{
					color: theme.palette.text.main,
				}}
			>
				{heading}
			</h1>
			<div
				className="pie-text-text-container"
				style={{
					justifyContent:
						screenSize === "small" || isMobile ? "flex-start" : "center",
				}}
			>
				{paragraphs.map((paragraph, index) => (
					<div
						key={index}
						className="pie-text-text"
						style={{ color: theme.palette.text.main }}
					>
						{paragraph}
					</div>
				))}
			</div>
			{isMobile || screenSize === "small" ? null : (
				<div className="pie-text-controls">
					<ArrowBackIosIcon
						className="pie-text-controls-icons"
						style={{
							color: isPrevHovered ? "#5dcec0" : theme.palette.text.main,
							cursor: "pointer",
						}}
						onMouseEnter={() => setIsPrevHovered(true)}
						onMouseLeave={() => setIsPrevHovered(false)}
						onClick={() => onIconClick("prev")}
					/>
					<SquareIcon
						className="pie-text-controls-icons"
						style={{
							color: theme.palette.text.main,
							transform: "rotate(135deg)",
						}}
					/>
					<ArrowBackIosIcon
						className="pie-text-controls-icons"
						style={{
							color: isNextHovered ? "#5dcec0" : theme.palette.text.main,
							transform: "rotate(180deg)",
							cursor: "pointer",
						}}
						onMouseEnter={() => setIsNextHovered(true)}
						onMouseLeave={() => setIsNextHovered(false)}
						onClick={() => onIconClick("next")}
					/>
				</div>
			)}
		</div>
	);
};

export default PieText;

import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";

const PieChart = ({ segments, onSegmentClick, activeSegmentIndex }) => {
  const theme = useTheme();
  const radius = 100;
  const strokeWidth = 1;
  const outerEdgeScale = 1;
  const [isVisible, setIsVisible] = useState(false);
  const pieChartRef = useRef(null);
  const total = segments.reduce((sum, seg) => sum + seg.value, 0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger the appear animation
        }
      },
      { threshold: 0.5 }
    );

    if (pieChartRef.current) {
      observer.observe(pieChartRef.current);
    }

    return () => {
      if (pieChartRef.current) {
        observer.unobserve(pieChartRef.current);
      }
    };
  }, []);

  if (!segments || segments.length === 0) {
    return <div>No segments available</div>;
  }

  const getPath = (startAngle, endAngle, isActive) => {
    const outerRadius = isActive ? radius * outerEdgeScale + 15 : radius;
    const innerRadius = isActive ? radius * outerEdgeScale - 60 : radius - 60;

    const startXOuter = outerRadius * Math.cos(startAngle);
    const startYOuter = outerRadius * Math.sin(startAngle);
    const endXOuter = outerRadius * Math.cos(endAngle);
    const endYOuter = outerRadius * Math.sin(endAngle);

    const startXInner = innerRadius * Math.cos(startAngle);
    const startYInner = innerRadius * Math.sin(startAngle);
    const endXInner = innerRadius * Math.cos(endAngle);
    const endYInner = innerRadius * Math.sin(endAngle);

    const largeArcFlag = endAngle - startAngle > Math.PI ? 1 : 0;

    return `
      M ${startXOuter} ${startYOuter}
      A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${endXOuter} ${endYOuter}
      L ${endXInner} ${endYInner}
      A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${startXInner} ${startYInner}
      Z
    `;
  };

  const activeSegmentStartAngle =
    (2 * Math.PI * segments.slice(0, activeSegmentIndex).reduce((sum, seg) => sum + seg.value, 0)) /
    total;
  const rotationAngle = -(
    ((activeSegmentStartAngle +
      (2 * Math.PI * segments[activeSegmentIndex].value) / (2 * total)) *
      180) /
    Math.PI
  );

  const finalRotation = rotationAngle; // This will be the final rotation based on the active segment

  return (
    <svg
      ref={pieChartRef}
      width="500"
      height="500"
      viewBox={`-${radius * outerEdgeScale + 40} -${
        radius * outerEdgeScale + 40
      } ${radius * 2 * outerEdgeScale + 80} ${radius * 2 * outerEdgeScale + 80}`}
      className={`pie-chart ${isVisible ? "visible" : ""}`}
      style={{
        // Start the pie chart at 180deg more than its final position, then animate to the final rotation
        transform: isVisible ? `rotate(${finalRotation}deg)` : `rotate(${finalRotation - 180}deg)`,
      }}
    >
      <defs>
        <filter id="shadow" x="0" y="0" width="150%" height="150%">
          <feDropShadow dx="1.1" dy="1.1" stdDeviation="0" floodColor="#3f9484" />
        </filter>
      </defs>

      {segments.map((segment, index) => {
        const startAngle =
          (2 * Math.PI * segments.slice(0, index).reduce((sum, seg) => sum + seg.value, 0)) /
          total;
        const endAngle =
          (2 * Math.PI * segments.slice(0, index + 1).reduce((sum, seg) => sum + seg.value, 0)) /
          total;

        const isActive = activeSegmentIndex === index;
        const midAngle = startAngle + (endAngle - startAngle) / 2;
        const offsetRadius = radius * 0.7;
        const iconSize = isActive ? 40 : 30;
        const adjustedIconSize = iconSize * 1.5;
        const x = offsetRadius * Math.cos(midAngle) - adjustedIconSize / 2;
        const y = offsetRadius * Math.sin(midAngle) - adjustedIconSize / 2;

        return (
          <React.Fragment key={index}>
            <path
              d={getPath(startAngle, endAngle, isActive)}
              fill={isActive ? "#afe9df" : segment.color}
              stroke="transparent"
              strokeWidth={strokeWidth}
              onClick={() => onSegmentClick(index)}
              className="pie-chart-segment"
              filter="url(#shadow)"
            />
            <foreignObject
              x={x}
              y={y}
              width={adjustedIconSize}
              height={adjustedIconSize}
              style={{ pointerEvents: "none" }}
            >
              <div className="pie-chart-container">
                {React.cloneElement(segment.icon, {
                  fontSize: isActive ? "inherit" : "default",
                  style: {
                    fontSize: isActive ? `${iconSize * 1.5}px` : `${iconSize}px`,
                    transform: `rotate(${(midAngle * 180) / Math.PI}deg)`,
                    transformOrigin: "center",
                    color: theme.palette.text.main,
                  },
                })}
              </div>
            </foreignObject>
          </React.Fragment>
        );
      })}
    </svg>
  );
};

export default PieChart;

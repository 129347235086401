import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { THEME_MODES, setThemeMode } from "../../store/reducers/userSlice";
import { ReactComponent as LightIcon } from "../../media/mode/light-mode.svg";
import { ReactComponent as DarkIcon } from "../../media/mode/dark-mode.svg";
import { ReactComponent as SelectedBgIcon } from "../../media/language/language-selected.svg";
import { ReactComponent as NotSelectedBgIcon } from "../../media/language/language-not-selected.svg";
import "../header/header.css";
import { translations } from "../../helpers/translations";

function ModeModal() {
	const dispatch = useDispatch();
	const currentTheme = useSelector((state) => state.user.theme);

	const currentLanguage = useSelector((state) => state.user.language);

	const { darkMode, lightMode } =
		translations[currentLanguage] || translations.en;

	const LightBgIcon =
		currentTheme === THEME_MODES.LIGHT ? SelectedBgIcon : NotSelectedBgIcon;
	const DarkBgIcon =
		currentTheme === THEME_MODES.DARK ? SelectedBgIcon : NotSelectedBgIcon;

	const lightTextColorClass =
		currentTheme === THEME_MODES.LIGHT ? "selected-language-text" : "";

	const darkTextColorClass =
		currentTheme === THEME_MODES.DARK ? "selected-language-text" : "";

        const handleModeSelect = (mode) => {
            if (currentTheme !== mode) {
              dispatch(setThemeMode(mode)); 
            }
          };

	return (
		<div className="language-mobile-modal">
			<div
				className={`menu-mobile-links ${
					currentTheme === THEME_MODES.LIGHT ? "selected" : ""
				}`}
				onClick={() => handleModeSelect(THEME_MODES.LIGHT)}
			>
				<div className={`menu-mobile-links-option-font ${lightTextColorClass}`}>
					{lightMode}
				</div>
				<div className="language-mobile-selected">
					<LightBgIcon className="language-desktop-selected-bg" />
					<LightIcon className="language-desktop-selected-option-icons" />
				</div>
			</div>
			<div
				className={`menu-mobile-links ${
					currentTheme === THEME_MODES.DARK ? "selected" : ""
				}`}
				onClick={() => handleModeSelect(THEME_MODES.DARK)}
			>
				<div className={`menu-mobile-links-option-font ${darkTextColorClass}`}>
					{darkMode}
				</div>
				<div className="language-mobile-selected">
					<DarkBgIcon className="language-desktop-selected-bg" />
					<DarkIcon className="language-desktop-selected-option-icons" />
				</div>
			</div>
		</div>
	);
}

export default ModeModal;

export const translations = {
    en: {
        aboutUs: "About Us",
        services: "Services",
        language: "Language:",
        mode: "Mode",
        darkMode: "Dark Mode",
        lightMode: "Light Mode",
        english: "English",
        serbian: "Serbian",
        turkish: "Turkish"
    },
    tr: {
        aboutUs: "Hakkımızda",
        services: "Hizmetler",
        language: "Dil:",
        mode: "Mod",
        darkMode: "Koyu Mod",
        lightMode: "Açık Mod",
        english: "İngilizce",
        serbian: "Sırpça",
        turkish: "Türkçe"
    },
    sr: {
        aboutUs: "O Nama",
        services: "Usluge",
        language: "Jezik:",
        mode: "Tema",
        darkMode: "Tamna Tema",
        lightMode: "Svetla Tema",
        english: "Engleski",
        serbian: "Srpski",
        turkish: "Turski"
    }
};

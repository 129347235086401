import React, { useState, useEffect, useRef } from "react";
import "./scrolleffect.css";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { selectActiveTheme } from "../../store/reducers/userSlice";
import { ReactComponent as LogoBorderBlack } from "../../media/logo/logo-single-black-border.svg";
import { ReactComponent as LogoBorderWhite } from "../../media/logo/logo-single-white-border.svg";

function ScrollEffectMobile() {
	const theme = useTheme();
	const themeMode = useSelector(selectActiveTheme);
	const [isLogoVisible, setIsLogoVisible] = useState(false);
	const containerRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			if (containerRef.current) {
				const rect = containerRef.current.getBoundingClientRect();
				if (rect.top <= window.innerHeight - 50 && rect.bottom >= 0) {
					setIsLogoVisible(true);
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<div
			className="overlay-mobile-container"
			ref={containerRef}
			style={{
				backgroundColor: theme.palette.primary.mainTransparent,
			}}
		>
			{themeMode === "light" ? (
				<LogoBorderBlack
					className={`overlay-logo-mobile ${isLogoVisible ? "visible" : ""}`}
				/>
			) : (
				<LogoBorderWhite
					className={`overlay-logo-mobile ${isLogoVisible ? "visible" : ""}`}
				/>
			)}
			<div
				className={`vertical-line-mobile ${isLogoVisible ? "expand" : ""}`}
				style={{
					backgroundColor: theme.palette.text.main,
				}}
			></div>
		</div>
	);
}

export default ScrollEffectMobile;

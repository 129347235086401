import React from "react";
import { ReactComponent as EnglishIcon } from "../../media/language/english.svg";
import { ReactComponent as TurkishIcon } from "../../media/language/turkey.svg";
import { ReactComponent as SerbianIcon } from "../../media/language/serbian.svg";
import { ReactComponent as LngNotSelected } from "../../media/language/language-not-selected.svg";
import { ReactComponent as LngSelected } from "../../media/language/language-selected.svg";
import { useSelector } from "react-redux";
import {  selectActiveLanguage } from "../../store/reducers/userSlice";
import { isMobile } from "react-device-detect";
import "./header.css";
import "../header-mobile/headermobile.css";
import { selectScreenSize } from "../../store/reducers/layoutSlice";

const languageIcons = {
    en: EnglishIcon,
    tr: TurkishIcon,
    sr: SerbianIcon,
};

function LanguageOption({ language, onClick }) {
    const Icon = languageIcons[language.code] || EnglishIcon;
    const screenSize = useSelector(selectScreenSize);
    const currentLanguage = useSelector(selectActiveLanguage);

    const containerClass =
        screenSize === "small" || isMobile
            ? "menu-mobile-links"
            : "menu-desktop-links";

    const optionClass =
        screenSize === "small" || isMobile
            ? "menu-mobile-links-option-font"
            : "menu-desktop-links-option-font";

    const selectedClass =
        screenSize === "small" || isMobile
            ? "language-mobile-selected"
            : "language-desktop-selected";

    const SelectedBgIcon =
        language.code === currentLanguage ? LngSelected : LngNotSelected;

    const textColorClass =
        language.code === currentLanguage ? "selected-language-text" : "";

    return (
        <div className={containerClass} onClick={onClick}>
            <div className={`${optionClass} ${textColorClass}`}>
                {language.label}
            </div>
            &nbsp;
            <div className={selectedClass}>
                <SelectedBgIcon className="language-desktop-selected-bg" />
                <Icon className="language-desktop-selected-option-icons" />
            </div>
        </div>
    );
}

export default LanguageOption;

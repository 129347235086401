import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import Logo from "../header/Logo";
import "./headermobile.css";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderMobileModal from "./HeaderMobileModal";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";

function HeaderMobile() {
	const theme = useTheme();
	const screenSize = useSelector(selectScreenSize);
	const [isModalOpen, setModalOpen] = useState(false);
	// const currentTheme = useSelector((state) => state.user.theme);

	const handleMenuClick = () => {
		setModalOpen((prev) => !prev);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	// const boxShadow = currentTheme === "dark"
	// 	? "0px 4px 6px rgba(255, 255, 255, 0.8)"
	// 	: "0px 4px 6px rgba(0, 0, 0, 0.3)";

	return (
		<div
			className="header-container-mobile"
			style={{
				backgroundColor: theme.palette.primary.main,
				position: screenSize === "small" || isMobile ? "fixed" : "",
			}}
		>
			<Logo />

			<div className="menu-mobile">
				<MenuIcon
					style={{
						fontSize: "2.2rem",
						color: theme.palette.text.main,
					}}
					onClick={handleMenuClick}
				/>
			</div>

			{isModalOpen && (
				<HeaderMobileModal isOpen={isModalOpen} onClose={handleCloseModal} />
			)}
		</div>
	);
}

export default HeaderMobile;

import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLanguage, LANGUAGE_OPTIONS } from "../../store/reducers/userSlice";
import { ReactComponent as LngSelected } from "../../media/language/language-selected.svg";
import { ReactComponent as EnglishIcon } from "../../media/language/english.svg";
import { ReactComponent as TurkishIcon } from "../../media/language/turkey.svg";
import { ReactComponent as SerbianIcon } from "../../media/language/serbian.svg";
import LanguageModal from "./LanguageModal";
import { useTheme } from "@emotion/react";

const languageIcons = {
	[LANGUAGE_OPTIONS.ENGLISH]: EnglishIcon,
	[LANGUAGE_OPTIONS.TURKISH]: TurkishIcon,
	[LANGUAGE_OPTIONS.SERBIAN]: SerbianIcon,
};

function LanguageSelect() {
	const dispatch = useDispatch();
	const currentLanguage = useSelector((state) => state.user.language);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const modalRef = useRef(null); // Ref for the modal

	const handleLanguageChange = (language) => {
		dispatch(setLanguage({ language }));
		setIsModalOpen(false);
	};

	const toggleModal = () => {
		setIsModalOpen((prevState) => !prevState);
	};

	const CurrentLanguageIcon = languageIcons[currentLanguage] || EnglishIcon;

	return (
		<div
			className="language-desktop-select"
		>
			<div className="language-desktop-selected" onClick={toggleModal}>
				<LngSelected className="language-desktop-selected-bg" />
				<CurrentLanguageIcon className="language-desktop-selected-icon" />
			</div>
			{isModalOpen && (
				<div ref={modalRef} className="modal-container">
					<LanguageModal
						onLanguageSelect={handleLanguageChange}
						CurrentLanguageIcon={CurrentLanguageIcon}
					/>
				</div>
			)}
		</div>
	);
}

export default LanguageSelect;

import React from "react";
import "./videotext.css";
import { useSelector } from "react-redux";
import { selectScreenSize } from "../../store/reducers/layoutSlice";
import { isMobile } from "react-device-detect";
import { useTheme } from "@emotion/react";
import { selectActiveLanguage } from "../../store/reducers/userSlice";

// Translations for the text in different languages
const translations = {
  en: "Your Vision. Our Solution.",
  tr: "Vizyonunuz. Bizim Çözümümüz.",
  sr: "Vaša Vizija. Naše Rešenje.",
};

function VideoText() {
  const screenSize = useSelector(selectScreenSize);
  const activeLanguage = useSelector(selectActiveLanguage); // Get the active language from Redux
  const theme = useTheme();

  // Select the translated text based on the active language
  const text = translations[activeLanguage];

  // Calculate delay for each character based on its index
  const getAnimationDelay = (index) => `${0.1 + index * 0.1}s`;

  return (
    <div
      className="video-text-container"
    >
      {text.split("").map((char, index) => (
        <span
          style={{
            color: theme.palette.text.main,
            animationDelay: getAnimationDelay(index),
          }}
          key={index}
        >
          {char === " " ? "\u00A0" : char} {/* Replace space with non-breaking space */}
        </span>
      ))}
    </div>
  );
}

export default VideoText;
